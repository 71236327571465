<template>
  <div>
    <top-decorations></top-decorations>
    <div class="small-intro">
      <intro-page :pageId="currentPageId"></intro-page>
    </div>
    <projects-list :showBest="true"></projects-list>
  </div>
</template>
<script>
import TopDecorations from "@/components/TopDecorations.vue";
import ProjectsList from "@/components/ProjectsList.vue";
import IntroPage from '../components/IntroPage.vue';

export default {
  components: { TopDecorations, ProjectsList,IntroPage },
  data() {
    return {
      currentPageId: "208d796e-0978-4ac4-a52e-2cd43aac7542",
    };
  },
  methods: {},
};
</script>

<style lang="scss">


</style>